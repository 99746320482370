<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'addProduct' })"
      :showSearch="true"
      v-model="params.search"
      :dropdownList="dropdownList"
      @dropdownChanged="dropdownChanged"
    >
      {{ $t("products.products") }}
    </dashboard-page-title>
    <main-table
      :fields="fields"
      :list_url="'merchant/products'"
      :params="params"
      paginationName="منتجات"
    ></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
// import productServices from '@/modules/products/services/products'
import products from "../services/products";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        {
          label: this.$t("products.mainImage"),
          key: "featured_image",
          type: "image",
          class: "text-start",
        },
        { label: this.$t("products.name"), key: "title", class: "text-start" },
        // { label: this.$t('products.images'), key: 'product_images', type: 'multi_image', class: 'text-start' },
        {
          label: this.$t("products.categories.title"),
          key: "category.name",
          class: "text-start",
        },
        {
          label: this.$t("products.price"),
          key: "net_price",
          class: "text-start",
        },
        {
          label: this.$t("products.quantity"),
          key: "quantity",
          class: "text-start",
        },
        {
          label: this.$t("products.date"),
          key: "created_at",
          class: "text-start",
          type: "dateTime",
        },
        {
          label: this.$t("products.status"),
          key: "status",
          type: "status",
          objectTranslation: "main.status",
          class: "text-start",
        },
        {
          label: this.$t("products.control"),
          key: "actions",
          type: "actions",
          class: "text-start",
          actions: [
            {
              text: "view",
              icon: "ri-eye-fill",
              color: "primary",
              ifNavigate: true,
              routePath: "show-product/:id",
              showIf: () => this.$perHelper("products:findOne"),
            },
            {
              text: "edit",
              icon: "ri-ball-pen-fill",
              color: "info",
              ifNavigate: true,
              routePath: "edit-product/:id",
              showIf: () => this.$perHelper("products:update"),
            },
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "merchant/products",
              titleHeader: "منتج",
              question: "هل متأكد أنك تريد مسح هذا المنتج",
              textContent: "name",
              textDeleteButton: "بالتأكيد, مسح",
              textCancelButton: "الغاء",
              showAlert: true,
            },
          ],
        },
      ],
      params: { status: "published", category_id: "", search: "" },
      dropdownList: [
        {
          selected: "published",
          options: [
            { title: this.$t("main.status.all"), value: "" },
            { title: this.$t("main.status.published"), value: "published" },
            { title: this.$t("main.status.hidden"), value: "hidden" },
          ],
        },
        { selected: "", options: [] },
      ],
    };
  },
  methods: {
    getAllCategories() {
      products
        .getAllCategories()
        .then((res) => {
          this.dropdownList[1].options = [
            { title: this.$t("main.status.all"), value: "" },
            ...res.data.data.map((item) => {
              return { title: item.name, value: item.id };
            }),
          ];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dropdownChanged(data) {
      if (data.index === 0) this.params.status = data.value;
      else if (data.index === 1) this.params.category_id = data.value;
    },
  },
  created() {
    this.getAllCategories();
  },
};
</script>
